// Throbber.js
import React from 'react';
import { useThrobber,  throbberText} from './PageThrobberContext';
import '../css/PageThrobber.css';

const Throbber = () => {
    const { isLoading, throbberText } = useThrobber();

    return (
        <div className={`throbber-overlay ${isLoading ? '' : 'disabled'}`}>
            <div className='throbber-container'>
                <div className="throbber-spinner"></div>
                <div className='throbber-loading-text'>
                   {throbberText}
                </div>
            </div>
        </div>
    );
};

export default Throbber;
