import { useEffect } from 'react';
import { useThrobber } from '../js/PageThrobberContext';

const useVideoPreloader = (videoRef, usePageThrobber) => {
    const { showThrobber, hideThrobber } = useThrobber();

    useEffect(() => {
        const waitForVideoBuffer = async () => {
          showThrobber('Preparing content...');
          await videoRef.current.play();
          hideThrobber();
        };
    
        waitForVideoBuffer();
      }, []);
};

export default useVideoPreloader;
