import React, { useEffect, useState } from 'react';
// import HomeThumbnailHeader from './HomeThumbnailHeader';
import WelcomeBox from '../components/js/WelcomeBox';
import FeaturedSlider from '../components/js/FeaturedSlider';
// import CategoriesSlider from './CategoriesSlider';
import GithubInfoBox from '../components/js/GithubInfoBox';
import ProjectArchive from '../components/js/ProjectArchive';
import PropTypes from 'prop-types';
import HomeThumbnailHeader from '../components/js/HomeThumbnailHeader';
import { useThrobber } from '../components/js/PageThrobberContext';


function Home(props) {

  const projects = props.projects;
  const featuredProjects = projects.filter(project => project.IS_FEATURED === "1");

  const [images, setImages] = useState({});
  const [imagesRetrieved, setImagesRetrieved] = useState(false);

  const { showThrobber, hideThrobber, updateThrobberText } = useThrobber();

  const retrieveProjectImages = async (projects) => {
    showThrobber(`Loading something amazing!`);
    let imageIDs = [];
    projects.forEach(project => {
      if (project.LOGO !== null) {
        imageIDs.push(project.LOGO);
      }
      if (project.THUMBNAIL !== null) {
        imageIDs.push(project.THUMBNAIL);
      }
      if (Array.isArray(project.HIGHLIGHTED_IMAGES)) {
        imageIDs = imageIDs.concat(project.HIGHLIGHTED_IMAGES);
      }
    });

    try {
      const response = await fetch(`https://koenhankel.nl/api/get_images.php?ids=${imageIDs.join(',')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      setImages(result.data);
      setImagesRetrieved(true);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
    hideThrobber();
  };

  useEffect(() => {
    retrieveProjectImages(projects);
  }, [projects])

  const [videos, setVideos] = useState({});
  const [videosRetrieved, setVideosRetrieved] = useState(false);

  const retrieveProjectVideos = async (projects) => {
    showThrobber();
    let videoIDs = [];
    projects.forEach(project => {
      if (project.VIDEO !== null) {
        videoIDs.push(project.VIDEO);
      }
    });

    try {
      const response = await fetch(`https://koenhankel.nl/api/get_videos.php?ids=${videoIDs.join(',')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      setVideos(result.data);
      setVideosRetrieved(true);
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
    hideThrobber();
  };

  useEffect(() => {
    retrieveProjectVideos(projects);
  }, [projects])

  if (!imagesRetrieved)
    return <div>Retrieving images...</div>

  if (!videosRetrieved)
    return <div>Retrieving videos...</div>

  return (
    <section id='home ' className='page'>
      <HomeThumbnailHeader/>
      <div className='my-container-flex'>
        <div className='col-11 col-lg-11 col-xl-10'>
          <FeaturedSlider projects={featuredProjects} images={images} videos={videos} />
          {/* <GithubInfoBox /> */}
          {/* <WelcomeBox /> */}
          {/* <CategoriesSlider /> */}
          <ProjectArchive projects={projects} images={images} videos={videos}/>
        </div>
      </div>
    </section>
  );
}

export default Home;

Home.propTypes = {
  projects: PropTypes.array,
};
