import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProjectBackground from './ProjectBackground';
import ProjectLogo from './ProjectLogo';
import ProjectDetails from './ProjectDetails';
import ProjectArchive from './ProjectBlocks';
import NewlineText from './NewlineText';
import RetrieveImages from './RetrieveImages';
import RetrieveVideos from './RetrieveVideos';
import { useThrobber } from './PageThrobberContext';
import { devLog } from '../utils/devLog';

import '../css/ProjectPage.css';

function ProjectPage(props) {
  const [project, setProjectData] = useState(props.project);
  const [projectPageFetching, setProjectPageFetching] = useState(true);
  const { showThrobber, hideThrobber, updateThrobberText } = useThrobber();

  useEffect(() => {
    const fetchProjectPage = async () => {
      try {
        showThrobber(`Loading ${project.PROJECT_NAME}...`);

        const response = await fetch(`https://koenhankel.nl/api/get_project_page.php?project_ID=${project.ID}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();
        if (result.status == 'success') {
          const projectData = result.data;
          setProjectData((prevProject) => ({
            ...prevProject,
            ...projectData,
            CONTENT_BLOCKS: JSON.parse(projectData.CONTENT_BLOCKS),
            BACKGROUND_IMAGES: JSON.parse(projectData.BACKGROUND_IMAGES),
            BACKGROUND_VIDEO: JSON.parse(projectData.BACKGROUND_VIDEO),
          }));
          setProjectPageFetching(false);
          hideThrobber();
        }
        else {
          console.error(result);
        }
      }
      catch (error) {
        console.error('Error:', error);
      }
    }

    if (projectPageFetching) fetchProjectPage();
  }, [projectPageFetching]);

  // #region Fetching content blocks
  const [contentBlocks, setContentBlocks] = useState({});
  const [contentFetching, setContentFetching] = useState(true);
  useEffect(() => {
    const getContentBlock = async (ids) => {
      try {
        if (!ids) return;
        showThrobber(`Loading content for ${project.PROJECT_NAME}...`);

        const api = 'https://koenhankel.nl/api/get_content_blocks.php';
        const queryString = `ids=${ids.join(',')}`;
        const url = `${api}?${queryString}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();
        if (result.status == 'success') {
          //Make result object
          const objResult = result.data;
          Object.keys(objResult).forEach((key) => {
            const item = objResult[key];
            if (item.IMG_IDS) {
              item.IMG_IDS = JSON.parse(item.IMG_IDS);
            }
            if (item.CONTENT) {
              item.CONTENT = JSON.parse(item.CONTENT);
            }
            if (item.VIDEO_IDS) {
              item.VIDEO_IDS = JSON.parse(item.VIDEO_IDS);
            }
          });
          setContentBlocks(objResult);
          setContentFetching(false);
          hideThrobber();
        }
        else {
          console.error(result);
        }
      } catch (error) {
        console.error('Error', error);
      }
    }
    //Only fetch when project pages finished fetching
    if (!projectPageFetching) getContentBlock(project.CONTENT_BLOCKS);
  }, [project.CONTENT_BLOCKS]);
  //#endregion

  // #region Fetching images
  const [images, setImages] = useState({});
  const [imageFetching, setImageFetching] = useState(true);//Assume it's fetching by default

  useEffect(() => {
    const fetchImages = async () => {
      const imageIDs = [];
      showThrobber('Loading images...');

      if (project.LOGO !== null) imageIDs.push(project.LOGO);
      if (project.THUMBNAIL !== null) imageIDs.push(project.THUMBNAIL);
      if (Array.isArray(project.HIGHLIGHTED_IMAGES)) imageIDs.push(...project.HIGHLIGHTED_IMAGES);
      if (Array.isArray(project.BACKGROUND_IMAGES)) imageIDs.push(...project.BACKGROUND_IMAGES);
      Object.keys(contentBlocks).forEach((key) => {
        const item = contentBlocks[key];
        if (Array.isArray(item.IMG_IDS) && item.IMG_IDS.length > 0) {
          imageIDs.push(...item.IMG_IDS);
        }
      });

      hideThrobber();
      RetrieveImages({ imageIDs, setImages, setImageFetching });
    };
    //Only once everything is loaded
    if (!contentFetching) fetchImages();
  }, [contentFetching]);

  const [videos, setVideos] = useState({});
  const [videoFetching, setVideosFetching] = useState(true);//Assume it's fetching by default

  useEffect(() => {
    const fetchVideos = async () => {
      showThrobber('Loading videos...');

      const videoIDs = [];
      videoIDs.push(project.BACKGROUND_VIDEO);
      Object.keys(contentBlocks).forEach((key) => {
        const item = contentBlocks[key];
        if (Array.isArray(item.VIDEO_IDS) && item.VIDEO_IDS.length > 0) {
          videoIDs.push(...item.VIDEO_IDS);
        }
      });

      RetrieveVideos({ videoIDs, setVideos, setVideosFetching });
      hideThrobber();
    };
    //Only once everything is loaded
    if (!contentFetching) fetchVideos();
  }, [contentFetching]);

  //#endregion
  if (projectPageFetching) {
    return <div>Loading {project.PROJECT_NAME}...</div>
  }
  if (contentFetching) {
    return <div>Loading content for {project.PROJECT_NAME}...</div>
  }
  if (imageFetching) {
    return <div>Loading images...</div>
  }
  if (videoFetching) {
    return <div>Loading videos...</div>
  }

  return (
    <section id='project-page' className='page' style={{ color: 'white' }}>{/*TODO: Get textcolor from DB */}
      <ProjectBackground project={project} images={images} videos={videos} />
      <section className='project-page-header' style={{ background: project.HEADER_OVERLAY }}>
        <ProjectLogo project={project} images={images} />
      </section>
      <section id='project-page-content' className='project-page-content' style={{ background: project.OVERLAY }}>
        <section className='project-description'>
          <NewlineText text={project.DESCRIPTION} />
        </section>
        <ProjectArchive project={project} images={images} videos={videos} contentBlocks={contentBlocks} />
      </section>
    </section>
  );

}

export default ProjectPage;
ProjectPage.propTypes = {
  project: PropTypes.object
};
