import React, { createContext, useContext, useState } from "react";
import { useThrobber } from './PageThrobberContext';
import PropTypes from "prop-types";

const MediaCacheContext = createContext();

export const MediaCacheProvider = ({ children }) => {
    const [images, setImages] = useState({});
    const [videos, setVideos] = useState({});
    const { showThrobber, hideThrobber } = useThrobber();

    const GetOrFetchImages = async (ids, showLoading = false) => {
        const requestedImagesData = [];
        const missingImages = [];

        for (const id of ids) {
            if (images[id]) {
                requestedImagesData.push(images[id]);
            } else {
                missingImages.push(id);
            }
        }

        if (missingImages.length > 0) {
            if (showLoading)
                showThrobber();

            const response = await fetch(`https://koenhankel.nl/api/get_images.php?ids=${missingImages.join(',')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            const requestedImagesData
                = result.data;
            setImages({ ...images, ...requestedImagesData });

            if (showLoading)
                hideThrobber();
        }
        return requestedImagesData;
    }

    const GetOrFetchVideos = async (ids, showLoading = false) => {
        const requestedVideosData = [];
        const missingVideos = [];

        for (const id of ids) {
            if (videos[id]) {
                requestedVideosData
                    .push(videos[id]);
            } else {
                missingVideos.push(id);
            }
        }

        if (missingVideos.length > 0) {
            if (showLoading)
                showThrobber();

            const response = await fetch(`https://koenhankel.nl/api/get_videos.php?ids=${missingVideos.join(',')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            const requestedVideosData
                = result.data;
            setVideos({...videos, ...requestedVideosData});

            if (showLoading)
                hideThrobber();
        }
        return requestedVideosData;
    }

    return (
        <MediaCacheContext.Provider value={{ images, setImages, videos, setVideos, GetOrFetchImages, GetOrFetchVideos }}>
            {children}
        </MediaCacheContext.Provider>
    );
}

MediaCacheProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const useMediaCache = () => {
    return useContext(MediaCacheContext);
}

export default MediaCacheProvider;