import React, { useState, useEffect } from "react";
import MediaSelect from "../components/js/mediaSelect";
import VideoMediaSelect from "../components/js/VideoMediaSelect";
import ProjectBlockForms from "../components/js/ProjectBlockForms";
import PropTypes from 'prop-types';
import { GetEmptyJsonBlock } from "../components/js/BlockTypes";

const lodash = require('lodash');

const API_GET_PROJECTS = "https://koenhankel.nl/api/getProjects.php";
const API_GET_PROJECT = "https://koenhankel.nl/api/get_project.php";
const API_UPDATE_PROJECT = "https://koenhankel.nl/api/update_project.php";
const API_CREATE_PROJECT = "https://koenhankel.nl/api/create_project.php";
const API_GET_CONTENT_BLOCKS = "https://koenhankel.nl/api/get_content_blocks.php";

const initialFormData = {
    project_id: null,
    project: {
        IS_ACTIVE: true,
        PROJECT_NAME: "",
        PROJECT_SLUG: "",
        IS_FEATURED: false,
        IS_HIDDEN: false,
    },
    project_details: {
        USP: "",
        DATE: "",
        DESCRIPTION: "",
        LOGO: [],
        THUMBNAIL: [],
        VIDEO: [],
        HIGHLIGHTED_IMAGES: [],
    },
    project_page: {
        CONTENT_BLOCKS: [],
        BACKGROUND_CSS: "linear-gradient(69.5deg, rgb(40, 48, 68) 2.3%, rgb(95 95 95) 97.6%)",
        BACKGROUND_VIDEO: [],
        LOOP_VIDEO: false,
        CAN_FULLSCREEN: true,
        BACKGROUND_IMAGES: [],
        HEADER_OVERLAY: "rgba(0, 0, 0,0.4)",
        OVERLAY: "linear-gradient(rgba(0, 0, 0,0)80%, rgba(0, 0, 0,0.4) 100%)",
    },
    content_blocks: [],
    content_blocks_to_delete: [],
};

function CreateProjectPage() {
    const [formData, setFormData] = useState(initialFormData);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);

    useEffect(() => {
        fetch(`${API_GET_PROJECTS}?showInactive=true`)
            .then((response) => response.json())
            .then((data) => setProjects(data))
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);

    useEffect(() => {
        if (selectedProject === "NEW") {
            setFormData(initialFormData);
            setSelectedProject(null);
            return;
        }

        if (selectedProject) {
            fetchProject();
        }
    }, [selectedProject]);

    const fetchProject = () => {
        fetch(`${API_GET_PROJECT}?project_ID=${selectedProject}`)
            .then((response) => response.json())
            .then((data) => {
                const projectData = data.data;
                console.log("Project data:", projectData);
                const requestFormData = {
                    project_id: selectedProject,
                    project: {
                        IS_ACTIVE: Object.prototype.hasOwnProperty.call(projectData, 'IS_ACTIVE') ? projectData.IS_ACTIVE : initialFormData.project.IS_ACTIVE,
                        PROJECT_NAME: projectData.PROJECT_NAME || initialFormData.project.PROJECT_NAME,
                        PROJECT_SLUG: projectData.PROJECT_SLUG || initialFormData.project.PROJECT_SLUG,
                        IS_FEATURED: Object.prototype.hasOwnProperty.call(projectData, 'IS_FEATURED') ? projectData.IS_FEATURED : initialFormData.project.IS_FEATURED,
                        IS_HIDDEN: Object.prototype.hasOwnProperty.call(projectData, 'IS_HIDDEN') ? projectData.IS_HIDDEN : initialFormData.project.IS_HIDDEN,
                    },
                    project_details: {
                        USP: projectData.USP || initialFormData.project_details.USP,
                        DATE: projectData.DATE || initialFormData.project_details.DATE,
                        DESCRIPTION: projectData.DESCRIPTION || initialFormData.project_details.DESCRIPTION,
                        LOGO: projectData.LOGO != null ? [String(projectData.LOGO)] : initialFormData.project_details.LOGO ?? [],
                        THUMBNAIL: projectData.THUMBNAIL != null ? [String(projectData.THUMBNAIL)] : initialFormData.project_details.THUMBNAIL ?? [],
                        VIDEO: projectData.VIDEO != null ? [String(projectData.VIDEO)] : initialFormData.project_details.VIDEO ?? [],
                        HIGHLIGHTED_IMAGES: projectData.HIGHLIGHTED_IMAGES
                            ? JSON.parse(projectData.HIGHLIGHTED_IMAGES)
                            : initialFormData.project_details.HIGHLIGHTED_IMAGES,
                    },
                    project_page: {
                        CONTENT_BLOCKS: JSON.parse(projectData.CONTENT_BLOCKS) || initialFormData.project_page.CONTENT_BLOCKS,
                        BACKGROUND_CSS: projectData.BACKGROUND_CSS || initialFormData.project_page.BACKGROUND_CSS,
                        BACKGROUND_VIDEO: JSON.parse(projectData.BACKGROUND_VIDEO) || initialFormData.project_page.BACKGROUND_VIDEO,
                        LOOP_VIDEO: projectData.LOOP_VIDEO || initialFormData.project_page.LOOP_VIDEO,
                        CAN_FULLSCREEN: projectData.CAN_FULLSCREEN || initialFormData.project_page.CAN_FULLSCREEN,
                        BACKGROUND_IMAGES: projectData.BACKGROUND_IMAGES
                            ? JSON.parse(projectData.BACKGROUND_IMAGES)
                            : initialFormData.project_page.BACKGROUND_IMAGES,
                        HEADER_OVERLAY: projectData.HEADER_OVERLAY || initialFormData.project_page.HEADER_OVERLAY,
                        OVERLAY: projectData.OVERLAY || initialFormData.project_page.OVERLAY,
                    },
                    content_blocks: [],
                    content_blocks_to_delete: [],
                };

                setFormData(requestFormData);
                getContentBlock(requestFormData.project_page.CONTENT_BLOCKS);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const handleProjectSelectChange = (e) => {
        const value = e.target.value;
        setSelectedProject(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Submitting form!");
        console.log(formData);

        try {
            const api = selectedProject ? API_UPDATE_PROJECT : API_CREATE_PROJECT;
            const jsonData = JSON.stringify(formData);
            const response = await fetch(api, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: jsonData,
            });

            const responseText = await response.text();
            console.log("Raw Response Text:", responseText);

            // Attempt to parse the response as JSON
            let result;
            try {
                result = JSON.parse(responseText);
            } catch (jsonError) {
                console.error("Error parsing JSON:", jsonError);
                console.error("Response Text:", responseText);
                return;
            }

            if (result.status === "success") {
                console.log("Success");
                fetchProject();
                // Refresh the content
            } else {
                console.error("Error Response:", result);
            }
        } catch (error) {
            console.error("Error", error);
        }
    };

    const handleBlockFormsChange = (updatedBlockForms, blockToDeleteId) => {
        // If blockToDeleteId is provided, add it to the list of blocks to delete
        setFormData((prevData) => ({
            ...prevData,
            content_blocks: updatedBlockForms,
            project_details: {
                ...prevData.project_details,
            },
            content_blocks_to_delete: blockToDeleteId
                ? [...prevData.content_blocks_to_delete, blockToDeleteId]
                : prevData.content_blocks_to_delete,
        }));
    };

    const getContentBlock = async (ids) => {
        try {
            if (!ids) return;
            const queryString = `ids=${ids.join(",")}`;
            const url = `${API_GET_CONTENT_BLOCKS}?${queryString}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).catch((error) => {
                console.error("Error fetching content blocks:", error);
            });

            const result = await response.json();
            if (result.status === "success") {
                const objResult = Object.values(result.data);
                Object.keys(objResult).forEach((key) => {
                    const item = objResult[key];
                    if (item.IMG_IDS) {
                        item.IMG_IDS = JSON.parse(item.IMG_IDS);
                    }
                    if (item.CONTENT) {
                        item.CONTENT = JSON.parse(item.CONTENT);
                    }
                    if (item.VIDEO_IDS) {
                        item.VIDEO_IDS = JSON.parse(item.VIDEO_IDS);
                    }
                });

                const accurateContentBlocks = objResult.map((block) => {
                    const blockType = block.BLOCK_TYPE;
                    const EmptyBlockContent = GetEmptyJsonBlock(blockType);
                    const blockContent = block.CONTENT;

                    const mergedContent = EmptyBlockContent;
                    // Copy the value from blockContent if the key exists in EmptyBlockContent
                    Object.keys(EmptyBlockContent).forEach((key) => {
                        if (blockContent[key] !== undefined) {
                            mergedContent[key] = blockContent[key];
                        }
                    });

                    return {
                        ...block,
                        CONTENT: mergedContent,
                    };
                });

                // Make sure objResult has the same order as ids
                const orderedResult = ids.map((id) => accurateContentBlocks.find((item) => item.ID === id));
                handleBlockFormsChange(orderedResult);
            } else {
                console.error(result);
                console.log
            }
        } catch (error) {
            console.error("Error", error);
        }
    };

    const projectSelect = (
        <div>
            <select name="selectedProject" value={selectedProject} onChange={handleProjectSelectChange}>
                <option key="NEW" value="NEW">
                    New Project
                </option>
                {projects.map((project) => (
                    <option key={project.ID} value={project.ID}>
                        {project.PROJECT_NAME}
                    </option>
                ))}
            </select>
        </div>
    );

    const updateProjectForm = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            project: {
                ...prevData.project,
                [key]: value,
            },
        }));
    };

    const updateProjectDetailsForm = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            project_details: {
                ...prevData.project_details,
                [key]: value,
            },
        }));
    };

    const updateProjectPageForm = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            project_page: {
                ...prevData.project_page,
                [key]: value,
            },
        }));
    };

    const MediaSelectItem = (props) => {
        const { formCategory, formKey, updateForm } = props;
        const [maxSelection] = useState(props.maxSelection ? props.maxSelection : 0);
        const [isOpen, setIsOpen] = useState(false);
        const [selectedMedia, setSelectedMedia] = useState(() => {
            const media = lodash.get(formData, `${formCategory}.${formKey}`) || [];
            return Array.isArray(media) ? media : [media]
        });

        useEffect(() => {
            const media = lodash.get(formData, `${formCategory}.${formKey}`) || [];
            // make sure media is an array
            setSelectedMedia(Array.isArray(media) ? media : [media]);
        }, [formData, formKey]);

        const toggleOpen = () => {
            setIsOpen(!isOpen);
            console.log("isOpen", isOpen);
        };

        const onConfirmSelection = (selectedImages) => {
            setSelectedMedia(selectedImages);
            updateForm(formKey, selectedImages);
            setIsOpen(false);
        };

        return (
            <div>
                <div type="button" onClick={(e) => { toggleOpen(); e.preventDefault(); }}>
                    <div>
                        {console.log("selectedMedia", selectedMedia)}
                        {selectedMedia.length > 0 ? selectedMedia.map((id) => (
                            <span key={id} style={{ paddingRight: "5px" }}>
                                {id}
                            </span>
                        )) : ("Nothing Selected")}
                    </div>
                </div>
                {isOpen && (
                    <MediaSelect
                        previouslySelected={selectedMedia}
                        onImagesSelected={onConfirmSelection}
                        maxSelection={maxSelection}
                        setIsOpen={setIsOpen}
                    />
                )}
            </div>
        );
    };

    MediaSelectItem.propTypes = {
        formCategory: PropTypes.string.isRequired,
        formKey: PropTypes.string.isRequired,
        maxSelection: PropTypes.number,
        updateForm: PropTypes.func,
    };

    const VideoMediaSelectItem = (props) => {
        const { formCategory, formKey, updateForm } = props;
        const [maxSelection] = useState(props.maxSelection ? props.maxSelection : 0);
        const [isOpen, setIsOpen] = useState(false);
        const [selectedVideos, setSelectedVideos] = useState(() => {
            const videos = lodash.get(formData, `${formCategory}.${formKey}`) || [];
            return Array.isArray(videos) ? videos : [videos
            ]
        });

        useEffect(() => {
            const videos = lodash.get(formData, `${formCategory}.${formKey}`) || [];
            // make sure media is an array
            setSelectedVideos(Array.isArray(videos) ? videos : [videos]);
        }, [formData, formKey]);

        const toggleOpen = () => {
            setIsOpen(!isOpen);
            console.log("isOpen", isOpen);
        };

        const onConfirmSelection = (selectedVideos) => {
            setSelectedVideos(selectedVideos);
            updateForm(formKey, selectedVideos);
            setIsOpen(false);
        };

        return (
            <div>
                <div>
                    {selectedVideos && selectedVideos.map((id) => (
                        <span key={id} style={{ paddingRight: "5px" }}>
                            {id}
                        </span>
                    ))}
                    <div type="button" onClick={(e) => { toggleOpen(); e.preventDefault(); }}>Select</div> {/* Type set to "button" */}
                </div>
                {isOpen && (
                    <VideoMediaSelect
                        previouslySelected={selectedVideos}
                        onImagesSelected={onConfirmSelection}
                        maxSelection={maxSelection}
                    />
                )}
            </div>
        );
    };

    VideoMediaSelectItem.propTypes = {
        formCategory: PropTypes.string.isRequired,
        formKey: PropTypes.string.isRequired,
        maxSelection: PropTypes.number,
        updateForm: PropTypes.func,
    };

    const [isUploading, setIsUploading] = useState(false);
    const [file, setFile] = useState(null);
    const [uploadResponse, setUploadResponse] = useState('');
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('video', file);

        setIsUploading(true);
        try {
            const response = await fetch('https://koenhankel.nl/api/upload.php', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            console.log(result);
            if (result.status === 'success') {
                setUploadResponse(`Video uploaded with id ${result.videoID}`);
            } else {
                setUploadResponse(`Error: ${result.message}`);
            }
        } catch (error) {
            console.error('Error uploading video:', error);
            setUploadResponse('Error uploading video: ' + error);
        }
        setIsUploading(false);
    }
    return (
        <div style={{ paddingTop: "10%", paddingLeft: "10px" }}>
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleUpload} disabled={isUploading}>Upload</button>
            {uploadResponse && <p>{uploadResponse}</p>}
            <div>
                <div onClick={() => console.log(formData)}>Log form</div>
                {projectSelect}
            </div>
            <form onSubmit={handleSubmit} >
                <div className="project-info-container d-flex">
                    {/* Project Fields */}
                    <fieldset className="border border-dark">
                        <legend>Project</legend>
                        <label htmlFor="project_name">
                            Project Name:
                            <input
                                id="project_name"
                                name="project_name"
                                type="text"
                                value={formData.project.PROJECT_NAME}
                                onChange={(e) => updateProjectForm("PROJECT_NAME", e.target.value)}
                            />
                        </label>
                        <br />
                        <label htmlFor="project_slug">
                            Project Slug:
                            <input
                                id="project_slug"
                                name="project_slug"
                                type="text"
                                value={formData.project.PROJECT_SLUG}
                                onChange={(e) => updateProjectForm("PROJECT_SLUG", e.target.value)}
                            />
                        </label>
                        <br />
                        <label htmlFor="is_active">
                            Is Active:
                            <input
                                id="is_active"
                                name="is_active"
                                type="checkbox"
                                checked={formData.project.IS_ACTIVE}
                                onChange={(e) => updateProjectForm("IS_ACTIVE", e.target.checked)}
                            />
                        </label>
                        <br />
                        <label htmlFor="is_featured">
                            Is Featured:
                            <input
                                id="is_featured"
                                name="is_featured"
                                type="checkbox"
                                checked={formData.project.IS_FEATURED}
                                onChange={(e) => updateProjectForm("IS_FEATURED", e.target.checked)}
                            />
                        </label>
                        <br />
                        <label htmlFor="is_hidden">
                            Is Hidden:
                            <input
                                id="is_hidden"
                                name="is_hidden"
                                type="checkbox"
                                checked={formData.project.IS_HIDDEN}
                                onChange={(e) => updateProjectForm("IS_HIDDEN", e.target.checked)}
                            />
                        </label>
                    </fieldset>
                    <fieldset className="border border-dark">
                        <legend>Project Details</legend>
                        <label htmlFor="usp">
                            USP:
                            <input
                                id="usp"
                                name="usp"
                                type="text"
                                value={formData.project_details.USP}
                                onChange={(e) => updateProjectDetailsForm("USP", e.target.value)}
                            />
                        </label>
                        <br />
                        <label htmlFor="date">
                            Date:
                            <input
                                id="date"
                                name="date"
                                type="text"
                                value={formData.project_details.DATE}
                                onChange={(e) => updateProjectDetailsForm("DATE", e.target.value)}
                            />
                        </label>
                        <br />
                        <label htmlFor="description">
                            Description:
                            <textarea
                                id="description"
                                name="description"
                                value={formData.project_details.DESCRIPTION}
                                onChange={(e) => updateProjectDetailsForm("DESCRIPTION", e.target.value)}
                            />
                        </label>
                        <br />
                        <label htmlFor="logo">
                            Logo:
                            <MediaSelectItem formCategory="project_details" formKey="LOGO" maxSelection={1} updateForm={updateProjectDetailsForm} />
                        </label>
                        <br />
                        <label htmlFor="thumbnail">
                            Thumbnail: <MediaSelectItem formCategory="project_details" formKey="THUMBNAIL" maxSelection={1} updateForm={updateProjectDetailsForm} />
                        </label>
                        <br />
                        <label htmlFor="video">
                            Video:
                            <VideoMediaSelectItem formCategory="project_details" formKey="VIDEO" maxSelection={1} updateForm={updateProjectDetailsForm} />
                        </label>
                        <br />
                        <label htmlFor="HIGHLIGHTED_IMAGES">
                            HIGHLIGHTED_IMAGES
                            <MediaSelectItem formCategory="project_details" formKey="HIGHLIGHTED_IMAGES" updateForm={updateProjectDetailsForm} />
                        </label>
                        <br />
                    </fieldset>
                </div>
                <fieldset className="border border-dark">
                    <legend>Project Page</legend>
                    <label htmlFor="background_css">
                        Background CSS:
                        <input
                            id="background_css"
                            name="background_css"
                            type="text"
                            value={formData.project_page.BACKGROUND_CSS}
                            onChange={(e) => updateProjectPageForm("BACKGROUND_CSS", e.target.value)}
                        />
                    </label>
                    <br />
                    <label htmlFor="background_video">
                        Background Video:
                        <VideoMediaSelectItem formCategory="project_page" formKey="BACKGROUND_VIDEO" updateForm={updateProjectPageForm} />
                    </label>
                    <br />
                    <label htmlFor="loop_video">
                        Loop Video:
                        <input
                            id="loop_video"
                            name="loop_video"
                            type="checkbox"
                            checked={formData.project_page.LOOP_VIDEO}
                            onChange={(e) => updateProjectPageForm("LOOP_VIDEO", e.target.checked)}
                        />
                    </label>
                    <br />
                    <label htmlFor="can_fullscreen">
                        Can Fullscreen:
                        <input
                            id="can_fullscreen"
                            name="can_fullscreen"
                            type="checkbox"
                            checked={formData.project_page.CAN_FULLSCREEN}
                            onChange={(e) => updateProjectPageForm("CAN_FULLSCREEN", e.target.checked)}
                        />
                    </label>
                    <br />
                    <label htmlFor="background_images">
                        Background Images:
                        <MediaSelectItem formCategory="project_page" formKey="BACKGROUND_IMAGES" updateForm={updateProjectPageForm} />
                    </label>
                    <br />

                    <label htmlFor="header_overlay">
                        Header Overlay:
                        <input
                            id="header_overlay"
                            name="header_overlay"
                            type="text"
                            value={formData.project_page.HEADER_OVERLAY}
                            onChange={(e) => updateProjectPageForm("HEADER_OVERLAY", e.target.value)}
                        />
                    </label>
                    <br />
                    <label htmlFor="overlay">
                        Overlay:
                        <input
                            id="overlay"
                            name="overlay"
                            type="text"
                            value={formData.project_page.OVERLAY}
                            onChange={(e) => updateProjectPageForm("OVERLAY", e.target.value)}
                        />
                    </label>
                    <br />
                    <ProjectBlockForms onBlockFormsChange={handleBlockFormsChange} blockForms={formData.content_blocks} />
                </fieldset>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default CreateProjectPage;
