import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../css/BackgroundVideo.css';
import { useThrobber } from './PageThrobberContext';


function BackgroundVideo(props) {
  const {
    videoCache,
    project,
    handleVideoEnded,
    videoRef = useRef(null),
  } = props;

  const { showThrobber, hideThrobber, updateThrobberText } = useThrobber();
  const backgroundVideo = videoCache[project.BACKGROUND_VIDEO]?.SRC;

  useEffect(() => {
    if (backgroundVideo && !project.LOOP_VIDEO && videoRef.current) {
      videoRef.current.addEventListener('ended', handleVideoEnded);

      return () => {
        videoRef.current.removeEventListener('ended', handleVideoEnded);
      };
    }
  }, [backgroundVideo, handleVideoEnded, project.LOOP_VIDEO, videoRef]);

  const handleLoadStart = () => {
    console.log('Load start');
    showThrobber('Preparing content...'); // Show the throbber when loading starts
  };

  const handleCanPlay = () => {
    console.log('Can play');
    hideThrobber(); // Hide the throbber when the video is ready to play
  };

  return (
    <section className="project-background-video">
      <video
        src={backgroundVideo}
        autoPlay
        muted
        loop={project.LOOP_VIDEO}
        ref={videoRef}
        onLoadStart={handleLoadStart} // Video starts buffering
        onCanPlay={handleCanPlay} // Video is ready to play
      />
    </section>
  );
}

export default BackgroundVideo;

BackgroundVideo.propTypes = {
  project: PropTypes.object,
  videoRef: PropTypes.shape({ current: PropTypes.instanceOf(HTMLVideoElement) }),
  handleVideoEnded: PropTypes.func,
  videoCache: PropTypes.object,
};