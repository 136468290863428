import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NewlineText from './NewlineText';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import VideoPlayer from './VideoPlayer';
import { BlockType } from './BlockTypes';

import '../css/ProjectBlocks.css';

function ProjectArchive(props) {
  const project = props.project;
  const images = props.images;
  const videos = props.videos;
  const contentBlocks = props.contentBlocks
  const orderedContentBlocks = project.CONTENT_BLOCKS.map(index => contentBlocks[index]);

  const blocks = RenderBlocks(orderedContentBlocks);

  function RenderBlocks(blocks) {
    return blocks.map((contentBlockData, index) => {
      let block = `Blocktype {${contentBlockData.BLOCK_TYPE}} not found`;
      switch (contentBlockData.BLOCK_TYPE) {
        case BlockType.INNER_BLOCKS:
          block = <>
            <h2>{contentBlockData.CONTENT.title}</h2>
            <div className='inner-block'>
              {RenderBlocks(contentBlockData.CONTENT.blocks)}
            </div>
          </>
          break;
        case BlockType.TEXT:
          block = <TextBlock content={contentBlockData.CONTENT} />;
          break;
        case BlockType.TEXT_IMAGE_HORIZONTAL:
          block = <TextImageBlock content={contentBlockData.CONTENT} images={images} />;
          break;
        case BlockType.TEXT_IMAGE_VERTICAL:
          block = <TextImageBelowBlock content={contentBlockData.CONTENT} images={images} />;
          break;
        case BlockType.TEXT_VIDEO_HORIZONTAL:
          block = <TextVideoBlock content={contentBlockData.CONTENT} videos={videos} />;
          break;
        case BlockType.TEXT_VIDEO_VERTICAL:
          block = <TextVideoBelowBlock content={contentBlockData.CONTENT} videos={videos} />;
          break;
        case BlockType.SWIPER_SLIDER_GALLERY:
          block = <SwiperSliderGallery content={contentBlockData.CONTENT} images={images} />;
          break;
        case BlockType.SWIPER_SLIDER_VIDOES:
          block = <SwiperSliderVideos content={contentBlockData.CONTENT} videos={videos} />;
          break;
        default:
          break;
      }
      return <div className='project-block' key={index}> {block}</div>
    }
    );
  }

  return (
    <section className='project-blocks'>
      {blocks}
    </section>
  );
}

export default ProjectArchive;
ProjectArchive.propTypes = {
  project: PropTypes.object,
  images: PropTypes.object,
  videos: PropTypes.object,
  contentBlocks: PropTypes.object,
};

function TextBlock(props) {
  const content = props.content;
  return (
    <>
      <h2>{content.title}</h2>
      <div className='project-block-content'>
        <div className='project-block-content-text'>
          <NewlineText text={content.paragraph} />
        </div>
      </div>
    </>
  );
}
TextBlock.propTypes = {
  content: PropTypes.object
};

function TextImageBlock(props) {
  const content = props.content;
  const invertedLayout = content.invertedLayout;
  return (
    <>
      <h2>{content.title}</h2>
      <div className={`project-block-content ${invertedLayout ? 'flex-row-reverse' : ''}`}>
        <div><NewlineText text={content.paragraph} /></div>
        <Gallery galleryImages={content.gallery} images={props.images} />
      </div>
    </>
  );
}
TextImageBlock.propTypes = {
  content: PropTypes.object,
  images: PropTypes.object,
};

function TextImageBelowBlock(props) {
  const content = props.content;
  const invertedLayout = content.invertedLayout;
  return (
    <>
      <h2>{content.title}</h2>
      <div className={`project-block-content`}>
        <div>
          <Gallery galleryImages={content.gallery} images={props.images} />
          <div><NewlineText text={content.paragraph} /></div>
        </div>
      </div>
    </>
  );
}
TextImageBelowBlock.propTypes = {
  content: PropTypes.object,
  images: PropTypes.object,
};

function TextVideoBlock(props) {
  const content = props.content;
  const videos = props.videos;

  const invertedLayout = content.invertedLayout;

  return (
    <>
      <h2>{content.title}</h2>
      <div className="project-block-content">
        {invertedLayout ? (
          <>
            <VideoGallery galleryVideos={content.video} videos={videos} />
            <div><NewlineText text={content.paragraph} /></div>
          </>
        ) : (
          <>
            <div><NewlineText text={content.paragraph} /></div>
            <VideoGallery galleryVideos={content.video} videos={videos} />
          </>
        )}
      </div>
    </>
  );
}

TextVideoBlock.propTypes = {
  content: PropTypes.object,
  videos: PropTypes.object,
};

function TextVideoBelowBlock(props) {
  const content = props.content;
  const videos = props.videos;

  return (
    <>
      <h2>{content.title}</h2>
      <div className='project-block-content'>
        <div>
          <VideoGallery galleryVideos={content.video} videos={videos} />
          <div><NewlineText text={content.paragraph} /></div>
        </div>
      </div>
    </>
  );
}
TextVideoBelowBlock.propTypes = {
  content: PropTypes.object,
  videos: PropTypes.object,
};


function Gallery(props) {
  const galleryImages = props.galleryImages;
  const images = props.images;

  if (!galleryImages || !images) return (<div>No images selected!</div>);

  // const portraitSide = (galleryImages.length == 3 && galleryImages[0].portraitSide);
  const imagesGallery = galleryImages.map((imageID, index) => (
    <div className='image-container' key={index}>
      <img src={images[imageID].MEDIUM} alt={images[imageID].ALT} width={'100%'} />
    </div>
  ));

  return (
    <section className='image-gallery-container'>
      <section className='image-gallery'>
        {imagesGallery}
      </section>
    </section>
  );
}
Gallery.propTypes = {
  galleryImages: PropTypes.array,
  images: PropTypes.object,
};

function VideoGallery(props) {
  const galleryVideos = props.galleryVideos;
  const videos = props.videos;

  if (!galleryVideos.length)
    return (<div>No videos selected!</div>);
  const videoGallery = galleryVideos.map((videoID, index) => {
    const video = videos[videoID];
    return (
      <div className='image-container' key={index}>
        <VideoPlayer videoSrc={video.SRC} />
      </div>
    );
  });

  return (
    <section className='image-gallery-container'>
      <section className='image-gallery'>
        {videoGallery ?? <div>No videos selected!</div>}
      </section>
    </section>
  );
}

VideoGallery.propTypes = {
  galleryVideos: PropTypes.array,
  videos: PropTypes.object,
};




function SwiperSliderGallery(props) {
  const content = props.content;
  const images = props.images;
  const gallery = content.gallery;
  const slides = gallery.map((imageID, index) => {
    const image = images[imageID];
    return (
      <SwiperSlide className='image-slide' key={index}>
        <div className='image-container'>
          <img src={image.MEDIUM} alt={image.ALT} />
          <NewlineText text={image.ALT} />
        </div>
      </SwiperSlide>
    );
  });

  return (
    <section className='swiper-slider-gallery'>
      <h2>{content.title}</h2>
      <Swiper
        modules={[Autoplay]}
        direction={'horizontal'}
        spaceBetween={25}
        slidesPerView={3.5}
        centeredSlides={true}
        loop={true}
        allowTouchMove={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
      >
        {slides}
      </Swiper>
    </section>
  );
}
SwiperSliderGallery.propTypes = {
  content: PropTypes.object,
  images: PropTypes.object,
};

function SwiperSliderVideos(props) {
  const content = props.content;
  const videos = props.videos;
  const videoSlides = content.video.map((videoID, index) => {
    const video = videos[videoID];
    return (
      <SwiperSlide className='video-slide' key={index}>
        <div className='video-container'>
          <VideoPlayer videoSrc={video.SRC} />
          <NewlineText text={video.ALT} />
        </div>
      </SwiperSlide>
    );
  });

  return (
    <section className='swiper-slider-videos'>
      <h2>{content.title}</h2>
      <Swiper
        modules={[Autoplay]}
        direction={'horizontal'}
        spaceBetween={25}
        slidesPerView={3.5}
        centeredSlides={true}
        loop={true}
        allowTouchMove={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
      >
        {videoSlides}
      </Swiper>
    </section>
  );
}

SwiperSliderVideos.propTypes = {
  content: PropTypes.object,
  videos: PropTypes.object,
};