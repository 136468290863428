import React from 'react';
import PropTypes from 'prop-types';

import '../css/ProjectLogo.css';
import ProjectDetails from './ProjectDetails';


function ProjectLogo(props) {
  const project = props.project;
  const logoID = project.LOGO;
  const images = props.images;
  return (
    <section className='project-logo'>
      {(logoID > 0) ? (
        <div className='project-logo-container'>
          <img src={images[project.LOGO].ORIGINAL} alt={`Logo of ${project.PROJECT_NAME}`} />
        </div>
      ) : <h1>{project.PROJECT_NAME}</h1>}
      <div className='categories'>
        <span>{project.CATEGORIES}</span>
      </div>
      <ProjectDetails project={project} />
      <p>{project.USP}</p>
    </section>
  );
}

export default ProjectLogo;
ProjectLogo.propTypes = {
  project: PropTypes.object,
  images: PropTypes.object,
};
