import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const PageThrobberContext = createContext();

export const PageThrobberProvider = ({ children }) => {

    const [throbProcesses, setThrobProcesses] = useState(0);
    const [throbberText, setThrobberText] = useState("Loading something amazing!");

    const showThrobber = (throbberText) => {
        setThrobProcesses((prev) => prev + 1);
        if (throbberText) setThrobberText(throbberText);
    }
    const hideThrobber = () => setThrobProcesses((prev) => Math.max(prev - 1, 0));
    const updateThrobberText = (text) => setThrobberText(text);
  
    const isLoading = throbProcesses > 0;

    return (
        <PageThrobberContext.Provider value={{ isLoading, showThrobber, hideThrobber, throbberText, updateThrobberText}}>
            {children}
        </PageThrobberContext.Provider>
    );
}

PageThrobberProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const useThrobber = () => {
    return useContext(PageThrobberContext);
}

export default PageThrobberProvider;