import PropTypes from 'prop-types';
import { devLog } from '../utils/devLog';

function RetrieveVideos({ videoIDs, setVideos, setVideosFetching }) {
    const retrieveProjectVideos = async () => {
        setVideosFetching(true);
        try {
            const response = await fetch(`https://koenhankel.nl/api/get_videos.php?ids=${videoIDs.join(',')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            setVideos(result.data);
            devLog('Videos retrieved:', result.data);
        } catch (error) {
            console.error('Error fetching videos:', error);
        }
        setVideosFetching(false);
    };

    retrieveProjectVideos();
}
export default RetrieveVideos;