export const BlockType = {
    TEXT: "TEXT",
    TEXT_IMAGE_HORIZONTAL: "TEXT_IMAGE_HORIZONTAL",
    TEXT_IMAGE_VERTICAL: "TEXT_IMAGE_VERTICAL",
    TEXT_VIDEO_HORIZONTAL: "TEXT_VIDEO_HORIZONTAL",
    TEXT_VIDEO_VERTICAL: "TEXT_VIDEO_VERTICAL",
    SWIPER_SLIDER_GALLERY: "SWIPER_SLIDER_GALLERY",
    INNER_BLOCKS: "INNER_BLOCKS",
    SWIPER_SLIDER_VIDOES: "SWIPER_SLIDER_VIDOES",
};

export function GetEmptyJsonBlock(blockType) {

    switch (blockType) {
        case BlockType.TEXT:
            return {
                title: '',
                paragraph: '',
            };
        case BlockType.TEXT_IMAGE_HORIZONTAL:
            return {
                title: '',
                paragraph: '',
                gallery: [],
                test: '',
                invertedLayout: false,
            };
        case BlockType.TEXT_IMAGE_VERTICAL:
            return {
                title: '',
                paragraph: '',
                gallery: [],
                invertedLayout: false,
            };
        case BlockType.TEXT_VIDEO_HORIZONTAL:
            return {
                title: '',
                paragraph: '',
                video: [],
                invertedLayout: false,
            };
        case BlockType.TEXT_VIDEO_VERTICAL:
            return {
                title: '',
                paragraph: '',
                video: [],
                invertedLayout: false,
            };
        case BlockType.SWIPER_SLIDER_GALLERY:
            return {
                title: '',
                gallery: [],
            };
        case BlockType.INNER_BLOCKS:
            return {
                title: '',
                blocks: [],
            };
        case BlockType.SWIPER_SLIDER_VIDOES:
            return {
                title: '',
                video: [],
            };
    }
}